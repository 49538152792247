import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "./BaseUrl";
import axios from "axios";
import { Button, Modal, Form, Spinner } from "react-bootstrap";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
const TaskList = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdate, setShowpdate] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");
  const [updateId, setUpdateId] = useState("");


  const handleShowUpdate = (id) => {
    setShowpdate(!showUpdate);
    setUpdateId(id._id);
    setEditedCategory(id.title)
  };

  const handleCloseShow = () => setShow(false);
  const handleCloseUpdate = () => setShowpdate(false);
  const [formData, setFormdata] = useState({ title: "" });
  const [Value, setValue] = useState({
    title: "",
  });
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});

  const fetchTodos = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${BaseUrl}/tasks/`);
      setData(response.data.tasks);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const HandleDelete = async (id) => {
    try {
      // Make a DELETE request to delete the title with the specified id
      await axios.delete(`${BaseUrl}/tasks/${id}`).then((res)=>{
        setData(data.filter((row) => row.id !== id));
        // Optionally, display a success message to the user

        if(res.status === 200){
          fetchTodos();
          toast.success("title deleted successfully");
        }
      })
    } catch (error) {
      console.error("Error deleting title:", error);
      // Display an error message to the user if deletion fails
      toast.error("Failed to delete title");
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = "title is required";
      isValid = false;
    } else if (formData.title.trim().length < 5) {
      newErrors.title = "title must be at least 5 characters long";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const RegisterUser = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await axios.post(`${BaseUrl}/tasks/`, formData);
      
      // Update data after successful response
      setFormdata(response.tasks);

      if (response.status === 201) {
        toast.success("To-Do List Add Successfully");
        handleCloseShow();
        fetchTodos();
     
       
      
      }
    } catch (error) {
      toast.error("Please check your details");
      console.error("Registration error:", error);
      
     
    }

    // Reset form data
    setFormdata({ title: "" });
  };


  const HandleUpdated = async (e) => {
    e.preventDefault();
  
    if (!Value.title) {
      toast.error("Please check all details");
      return; // Added return to exit the function if title is not provided
    }
  
    // Show loader
   

    try {
      const response = await axios.put(`${BaseUrl}/tasks/${updateId}`, Value);
  
      console.log(response, "res");
      if (response.status === 200) {
        // Update the specific item in the array with the updated data if necessary
        toast.success("Update Successfully");
        setIsLoading(true);
        handleCloseUpdate();
        // setShowpdate(!showUpdate); // This line toggles the modal, remove if not needed
        fetchTodos(); // Assuming fetchData is a function to fetch updated data
      }
    } catch (error) {
      console.error("Update error:", error);
      toast.error("Failed to update");
    } finally {
      // Hide loader after handling the response (whether success or error)
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchTodos();
    setTimeout(()=>{
      setIsLoading(false)
    },5000)
  }, []);
  return (
    <div className="container">
      <div className="main-admin mt-4 m-2">
        <div className="d-flex justify-content-between ">
          <h3 className="mt-4 mb-2"> TaskList</h3>
          <Link onClick={handleShow} className="btns  mt-4 mb-2 modal-header">
            {" "}
            To-Do Add
          </Link>
        </div>
        {isLoading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
    <Spinner animation="border" role="status" />
  </div>
) : (
        <div className="table-responsive">
          <Table className="table">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th> Title</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>

  <>
  
  {data.length === 0 ? (
  <tr>
    <td colSpan="3" className="text-center">
      No tasks available
    </td>
  </tr>
) : (data.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row?.title}</td>
                  <td>
                    <Link onClick={() => handleShowUpdate(row)}>
                      <FaEdit className="edit-btn  text-blue fs-4" />
                    </Link>
                    <Link onClick={() => HandleDelete(row._id)}>
                      <MdDelete className="text-danger fs-4" />
                    </Link>
                  </td>
                </tr>
               ))
               )}

              </>
            
            </tbody>
          </Table>
        </div>
          )}

 
      </div>
      <Modal show={show} onHide={handleCloseShow}>
        <Modal.Header closeButton>
          <Modal.Title>TO-DO List Add </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="container mt-3 mb-3">
            <Form.Group id="title" className="col col-sm-12 mt-3 mb-2">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                isInvalid={!!errors.title}
                onChange={(e) =>
                  setFormdata({ ...formData, title: e.target.value })
                }
                className="form-control"
                placeholder="Enter title"
              />
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseShow}>
            Close
          </Button>
          
          <button type="submit" className="btn-submit" onClick={RegisterUser}>
            Submit
          </button>
        
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdate} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>TO-DO List Update </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="container mt-3 mb-3">
            <Form.Group id="name" className=" col-sm-12 mt-3 mb-2">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                defaultValue={editedCategory}
                isInvalid={!!errors.title}
                onChange={(e) =>
                  setValue((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }))
                }
                className="form-control"
                placeholder="Enter title"
              />

              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdate}>
            Close
          </Button>
          <button type="button" className="btn-submit" onClick={HandleUpdated}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TaskList;
