import React from 'react';
import './App.css';
import { BrowserRouter as Router , Routes , Route  } from 'react-router-dom';
import TaskList from './TaskList';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
    <div className="App">
    <ToastContainer/>
      <Router>
      <Routes>
        <Route path='/' element={<TaskList/>}  />
      </Routes>
    </Router>
    </div>
    </>
  );
}

export default App;
